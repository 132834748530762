import ImageService from "@/service/image.service";

export default {
	name: 'AlbumsBody',
	data() {
        return {
            foldersName : undefined,
			mybutton : document.getElementById("myBtn"),
			url : undefined,
			positionx : undefined,
			positiony : undefined,
			file: ""
        }
    },
	methods: {
		next(){
			if(this.positiony < this.foldersName[this.positionx].file_name.length-1){
				this.positiony++;
			}
			else{
				if(this.positionx < this.foldersName.length-1)
				{
					this.positionx++;
					this.positiony = 0;
				}
				else{
					this.positionx = 0;
					this.positiony = 0;
				}
			}
			this.url = this.foldersName[this.positionx].file_name[this.positiony];
		},
		back(){
			if(this.positiony > 0){
				this.positiony--;
			}
			else{
				if(this.positionx >0)
				{
					this.positionx --;
					this.positiony = this.foldersName[this.positionx].file_name.length-1;
				}
				else{
					this.positionx = this.foldersName.length-1;
					this.positiony = this.foldersName[this.positionx].file_name.length-1;
				}
			}
			this.url = this.foldersName[this.positionx].file_name[this.positiony];
		},
		detailImg(positionx,positiony){
			console.log(positionx,positiony);
			this.url = this.foldersName[positionx].file_name[positiony];
			this.positionx = positionx;
			this.positiony = positiony;
		},
		onUploadButtonClick() {
			this.$refs.file.click()
		},

		selectFile(){
			this.file = event.target.files[0]
			console.log(this.file)
			this.$refs.upload.click()
		},

		async sendFile(){
			let formData = new FormData()
			formData.append('file', this.file)
			console.log(formData)
			let response = await ImageService.upload(formData)
			console.log(response);
			response = await ImageService.retrieveAllFoldersImages()
			this.foldersName = response.data.data
		}
		
	},
	async created(){
		let response = await ImageService.retrieveAllFoldersImages()
		this.foldersName = response.data.data
		console.log(this.foldersName);
	},
	
}
