import SignBody from "../../components/SignBody/SignBody.vue";

export default {
    name: 'SignScreen',
    components: {
		SignBody
	},
    data() {
        return {
        };
    },
    methods: {

    },
};
