export default {
	name: 'SignBody',
	data() {
        return {
			userName : "",
			passWord : "",
			Status : 1
        };
    },
	methods: {
		sign(){
			if(this.Status == 1){
				this.signIn();
			}
		},
		signIn() {
			if (this.userName == "bebebow" && this.passWord == "bow123"){
				window.location.href = "https://bebebow.netlify.app/home"
			} else {
				alert("Wrong password")
			}
		},
		Change(){
			this.Status = !this.Status;
			console.log(this.Status);
		}
	}
	
};
