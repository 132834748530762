import ApiService from "./api.service";

// Constant
const baseUrl = 'image/'

const ImageService = {
	retrieveFoldersName(params){
		return ApiService.get(baseUrl, params)
	},

	retrieveAllFoldersImages(params){
		return ApiService.get(baseUrl + 'file-name', params)
	},
	/**
	 * Retrieve all images from upload folder
	 * @param {Params} params 
	 * @returns {Response} response
	 */
	retrieveUploadedImages(params) {
		return ApiService.get(baseUrl, params);
	},
	/**
	 * Delete image
	 * @param {Params} params 
	 * @returns {Response} response
	 */
	delete(params) {
		return ApiService.delete(baseUrl, params);
	},
	/**
	 * Upload image to server directly from client side 
	 * @param {Params} params 
	 * @returns {Response} response
	 */
	upload(params) {
		return ApiService.post(baseUrl + 'upload', params);
	},
};

export default ImageService;