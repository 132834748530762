
import { RouterLink } from 'vue-router';

<template>
    <div class="container1">
        <RouterLink to="/">
            <div class="logo"></div>    
        </RouterLink>
        <div class="content-container">
            <div>
                <RouterLink to="/home" class="content">
                    Home
                </RouterLink>
            </div>
            <div>
                <RouterLink to="/albums" class="content">
                    Album
                </RouterLink>
            </div>
        </div>
        <div class="divlogout">
            <RouterLink to="/"  class="logoutbutton">
                Log out
            </RouterLink>
        </div>
    </div>
</template>
<style scoped src ="./HeaderPage.css"/>
<script src="./HeaderPage.js"></script>