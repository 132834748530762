

export default {
	name: 'HeaderPage',
	data() {
        return {
            
        }
    },

};
