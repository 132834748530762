<template>
    <div class="background1" v-on:keyup.enter="sign()">
        <div class="layer">
            <div class="containersignin" v-if="this.Status" >
                <div class="content">Log in</div>
                <div class="divgroup">
                    <div class="form-group">
                        <input type="text" v-model="userName" class="input" name="username" placeholder="Username" />
                    </div>
                    <div class="form-group">
                        <input type="password" v-model="passWord" class="input" name="password" placeholder="Password"/>
                    </div>
                    <div class="divlog" >
                        <button class="log" @click="signIn()" >Log in</button>
                    </div>
                </div>
                <div class="divlog">
                    <button class="log" @click="Change()" >
                        Sign up
                    </button>
                </div>
            </div>
            <div class="containersignup" v-else>
                <div class="content">Sign up</div>
                <div class="divgroup">
                    <div class="form-group">
                        <input type="text" v-model="userNameS" class="input" name="username" placeholder="Username"/>
                    </div>
                    <div class="form-group">
                        <input type="password" v-model="passWordS" class="input" name="password" placeholder="Password"/>
                    </div>
                    <div class="divlog">
                        <button class="log" >Sign up</button>
                    </div>
                </div>
                <div class="divlog">
                    <button class="log" @click="Change()" >
                        Sign in
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped src ="./SignBody.css"/>
<script src="./SignBody.js"></script>