<template>
  <div class="container1">
    <div class="background1">
    </div>
    <div class="inputfile">
      <!-- <input ref="myInput" class ="chossetype" type ="file"/>
        <input type = "submit"/> -->
      <form method="POST" enctype="multipart/form-data">
        <input type="file" ref="file" @change="selectFile" name="file" hidden/>
        <input ref="upload" @click="sendFile" hidden/>
      </form>
      <button class="uploadicon" @click="onUploadButtonClick">
        <img src="../../assets/upload.png">
      </button>
    </div>

    <div class="container2">
      <div v-for="image, index in foldersName" :key="index" class="container3">
        <h1 class="date">{{ image.date }}</h1>
        <br />
        <div class="containpicture">
          <div v-for="data, index1 in image.file_name" v-bind:key="index1">
            <button type="button" class="viewpic" data-bs-toggle="modal" data-bs-target="#exampleModal"
              @click="detailImg(index, index1)">
              <img :src="`${data}`" class="picture" alt="">
            </button>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="containimgzoom">
                  <img :src="`${url}`" class="picturezoom" alt="">
                </div>
              </div>
              <div class="modal-footer">
                <div class="containerbutton">
                  <button class="nextpicbutton" @click="back()">
                  </button>
                  <button class="nextpicbutton" @click="next()">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button style="{display:none}" id="myBtn" class="ontopbutton"></button>
  </div>
</template>
<style scoped src ="./AlbumsBody.css"/>
<script src="./AlbumsBody.js"></script>