import HeaderPage from "../../components/HeaderPage/HeaderPage.vue";
import AlbumsBody from "../../components/AlbumsBody/AlbumsBody.vue";

export default {
    name: 'AlbumsScreen',
    components: {
		HeaderPage,
        AlbumsBody
	},
    data() {
        return {
        };
    },
    methods: {

    },
};
