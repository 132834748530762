<template>
    <div class="background1">
        <div class="layer">
            <!-- Button trigger modal -->
            <!-- <div class="container1">
                bla bla bla
            </div> -->
        </div>
    </div>
</template>
<style scoped src ="./HomeBody.css"/>
<script src="./HomeBody.js"></script>