import { createRouter, createWebHistory } from 'vue-router';
import HomeScreen from '../screen/HomeScreen/HomeScreen.vue';
import AlbumsScreen from '../screen/AlbumsScreen/AlbumsScreen.vue';
import SignScreen from '../screen/SignScreen/SignScreen.vue';

const routes = [
	{
		path: '/',
		name: 'SignScreen',
		component: SignScreen,
	},
	{
		path: '/home',
		name: 'HomeScreen',
		component: HomeScreen,
	},
	{
		path: '/albums',
		name: 'AlbumsScreen',
		component: AlbumsScreen,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
